define("baadmin/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _jsonApi.default {
    keyForAttribute(attr, method) {
      return Ember.String.underscore(attr).toLowerCase();
    }

  }

  _exports.default = ApplicationSerializer;
});