define("baadmin/authenticators/token", ["exports", "ember-simple-auth/authenticators/base", "fetch"], function (_exports, _base, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    async restore(data) {
      let {
        accessToken
      } = data;

      if (accessToken) {
        return data;
      } else {
        throw 'no valid session data';
      }
    },

    authenticate(email, password) {
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        try {
          const response = await (0, _fetch.default)('http://dev.bizautomata.com/auth/v1/user/sign_in', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            body: JSON.stringify({
              email,
              password
            })
          });
          if (!response.ok) return Ember.run(null, reject, response);
          const responseData = await response.json();
          const result = {
            accessToken: response.headers.map['access-token'],
            expiry: response.headers.map['expiry'],
            tokenType: response.headers.map['token-type'],
            uid: response.headers.map['uid'],
            client: response.headers.map['client'],
            userId: responseData.data.id
          };
          Ember.run(null, resolve, result);
        } catch (error) {
          Ember.run(null, reject, error);
        }
      });
    },

    async invalidate(data) {
      (0, _fetch.default)('http://dev.bizautomata.com/auth/v1/user/sign_out', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'token-type': data.tokenType,
          'access-token': data.accessToken,
          expiry: data.expiry,
          uid: data.uid,
          client: data.client
        }
      }).then(response => {
        if (response.ok) {
          return Promise.resolve();
        } else {
          Ember.run(null, Ember.RSVP.Promise.reject(), response.text());
        }
      }).catch(error => Ember.run(null, Ember.RSVP.Promise.reject(), error));
    }

  });

  _exports.default = _default;
});