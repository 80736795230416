define("baadmin/components/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
    <button class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
      <span class="sr-only">Open sidebar</span>
      <!-- Heroicon name: outline/menu-alt-2 -->
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
      </svg>
    </button>
    <div class="flex-1 px-4 flex justify-between">
      <Navbar::Search />
      <div class="ml-4 flex items-center md:ml-6">
        <Navbar::Notifications />
        <Navbar::Profile />
      </div>
    </div>
  </div>
  {{yield}}
  */
  {
    "id": "dMuldVxy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"relative z-10 flex-shrink-0 flex h-16 bg-white shadow\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Open sidebar\"],[13],[2,\"\\n    \"],[3,\" Heroicon name: outline/menu-alt-2 \"],[2,\"\\n    \"],[10,\"svg\"],[14,0,\"h-6 w-6\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke\",\"currentColor\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n      \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M4 6h16M4 12h16M4 18h7\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-1 px-4 flex justify-between\"],[12],[2,\"\\n    \"],[8,\"navbar/search\",[],[[],[]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-4 flex items-center md:ml-6\"],[12],[2,\"\\n      \"],[8,\"navbar/notifications\",[],[[],[]],null],[2,\"\\n      \"],[8,\"navbar/profile\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "baadmin/components/navbar.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});