define("baadmin/routes/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PasswordResetRoute extends Ember.Route {}

  _exports.default = PasswordResetRoute;
});