define("baadmin/routes/internal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InternalRoute extends Ember.Route {}

  _exports.default = InternalRoute;
});