define("baadmin/services/network-request", ["exports", "baadmin/config/environment", "baadmin/utils/delete-nulls", "fetch"], function (_exports, _environment, _deleteNulls, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * As the Ember community moves away from jQuery, we still need some tidy
   * wrapping of the `fetch` library. This will do for now until the
   * community has a broader way of handling this.
   */
  let NetworkRequest = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class NetworkRequest extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "maintenanceDetection", _descriptor2, this);

      const node = document.querySelector('meta[name="csrf-token"]');
      const csrfToken = node.getAttribute('content');
      const {
        APP: {
          buildDate
        }
      } = _environment.default;
      this.defaultRequestHeaders = {
        'X-Build-Date': buildDate,
        'X-CSRF-Token': csrfToken
      };
    }

    _buildOptions(options) {
      const {
        defaultRequestHeaders
      } = this;
      const {
        headers: requestHeaders
      } = options;
      delete options.headers;
      const finalRequestHeaders = { ...defaultRequestHeaders,
        ...requestHeaders
      };
      options.headers = (0, _deleteNulls.default)(finalRequestHeaders);
      return options;
    }

    async call(url, options = {}) {
      const finalOptions = this._buildOptions(options);

      const response = await (0, _fetch.default)(url, finalOptions);
      const {
        headers,
        status
      } = response;

      if (status === 401 || headers.get('x-force-reload') === 'true') {
        this.session.invalidate.perform();
      }

      if (status === 503 || status === 502) {
        this.maintenanceDetection.serverOffline();
      }

      return response;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "maintenanceDetection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NetworkRequest;
});