define("baadmin/utils/delete-nulls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deleteNulls;

  /*
   * Removes keys with `null` values from an object.
   * Returns the original object
   */
  function deleteNulls(obj) {
    Object.keys(obj).forEach(key => obj[key] === null && delete obj[key]);
    return obj;
  }
});