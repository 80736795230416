define("baadmin/adapters/application", ["exports", "@ember-data/adapter/json-api", "baadmin/utils/server-unavailable-error", "baadmin/config/environment", "ember-new-version-detection/utils/fetch-header"], function (_exports, _jsonApi, _serverUnavailableError, _environment, _fetchHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    APP: {
      buildDate
    },
    'ember-new-version-detection': {
      appName
    },
    namespace,
    host
  } = _environment.default;
  let ApplicationAdapter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class ApplicationAdapter extends _jsonApi.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", host);

      _defineProperty(this, "namespace", namespace);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "serverMaintenance", _descriptor2, this);

      _initializerDefineProperty(this, "newVersionDetector", _descriptor3, this);

      _defineProperty(this, "accessToken", this.session.data.authenticated.accessToken);

      _defineProperty(this, "expiry", this.session.data.authenticated.expiry);

      _defineProperty(this, "tokenType", this.session.data.authenticated.tokenType);

      _defineProperty(this, "uid", this.session.data.authenticated.uid);

      _defineProperty(this, "client", this.session.data.authenticated.client);

      _defineProperty(this, "isAuthenticated", this.session.isAuthenticated);
    }

    get headers() {
      const headers = super.headers || {};
      const appHeaders = { ...headers,
        ...{
          'X-App-Name': appName,
          'X-App-Version': this.newVersionDetector.reportedVersion,
          'X-Build-Date': buildDate
        }
      }; // appHeaders['token-type'] = this.session.data.authenticated.tokenType;
      // appHeaders['access-token'] = this.session.data.authenticated.accessToken;
      // appHeaders['expiry'] = this.session.data.authenticated.expiry;
      // appHeaders['uid'] = this.session.data.authenticated.uid;
      // appHeaders['client'] = this.session.data.authenticated.client;

      appHeaders['token-type'] = this.tokenType;
      appHeaders['access-token'] = this.accessToken;
      appHeaders['expiry'] = this.expiry;
      appHeaders['uid'] = this.uid;
      appHeaders['client'] = this.client;
      return appHeaders;
    }

    handleResponse(status, headers, payload) {
      Ember.run.next(this, function () {
        this.newVersionDetector.set('activeVersion', (0, _fetchHeader.default)('X-Current-Version', headers));
        this.tokenType = (0, _fetchHeader.default)('token-type', headers);
        this.expiry = (0, _fetchHeader.default)('expiry', headers);
        this.uid = (0, _fetchHeader.default)('uid', headers);
        this.client = (0, _fetchHeader.default)('client', headers); // if the server detects a batch request (under 5 secs from last)
        // it will not send a token, and the existing one is valid for
        // the next request

        const accessToken = (0, _fetchHeader.default)('access-token', headers);
        if (accessToken !== '') this.accessToken = accessToken;
      });

      if ((0, _fetchHeader.default)('X-Force-Reload', headers) === 'true') {
        this.session.invalidate();
        return payload;
      }

      if (status === 401) {
        this.session.invalidate();
        return;
      }

      if (status === 503 || status === 502) {
        this.maintenanceDetection.serverOffline();
        return new _serverUnavailableError.default();
      }

      return super.handleResponse(...arguments);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "serverMaintenance", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "newVersionDetector", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationAdapter;
});