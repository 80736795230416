define("baadmin/components/sidebar/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    {{#if @model}}
      <LinkTo
        @route={{@route}}
        @model={{@model}}
        @activeClass={{@activeClass}}
        @current-when={{@currentWhen}}
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{else}}
      <LinkTo
        @route={{@route}}
        @activeClass={{@activeClass}}
        @current-when={{@currentWhen}}
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{/if}}
  {{else if @href}}
    {{#if @external}}
      <a
        href={{@href}}
        target="_blank"
        rel="noreferrer noopener"
        ...attributes
      >
        {{yield}}
      </a>
    {{else}}
      <a href={{@href}} ...attributes>
        {{yield}}
      </a>
    {{/if}}
  {{else}}
    <button type="button" ...attributes>
      {{yield}}
    </button>
  {{/if}}
  */
  {
    "id": "R/DUnkv7",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@href\",\"@external\",\"@route\",\"@activeClass\",\"@currentWhen\",\"@model\"],\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[17,1]],[[\"@route\",\"@model\",\"@activeClass\",\"@current-when\"],[[32,5],[32,8],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[[17,1]],[[\"@route\",\"@activeClass\",\"@current-when\"],[[32,5],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[16,6,[32,3]],[24,\"target\",\"_blank\"],[24,\"rel\",\"noreferrer noopener\"],[17,1],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"a\"],[16,6,[32,3]],[17,1],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[17,1],[24,4,\"button\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "baadmin/components/sidebar/link.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});