define("baadmin/templates/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x7inx23T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"h-screen flex overflow-hidden bg-gray-100\"],[12],[2,\"\\n  \"],[8,\"sidebar\",[],[[],[]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex flex-col w-0 flex-1 overflow-hidden\"],[12],[2,\"\\n    \"],[8,\"navbar\",[],[[],[]],null],[2,\"\\n    \"],[10,\"main\"],[14,0,\"flex-1 relative overflow-y-auto focus:outline-none\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"py-6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"max-w-7xl mx-auto px-4 sm:px-6 md:px-8\"],[12],[2,\"\\n          \"],[10,\"h1\"],[14,0,\"text-2xl font-semibold text-gray-900\"],[12],[2,\"Dashboard\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"max-w-7xl mx-auto px-4 sm:px-6 md:px-8\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"py-4\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "baadmin/templates/shop.hbs"
    }
  });

  _exports.default = _default;
});