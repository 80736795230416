define("baadmin/services/new-version-detector", ["exports", "ember-new-version-detection/services/new-version-detector"], function (_exports, _newVersionDetector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _newVersionDetector.default;
    }
  });
});