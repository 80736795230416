define("baadmin/utils/server-unavailable-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // NOTE: https://medium.com/@xpl/javascript-deriving-from-error-properly-8d2f8f315801
  class ServerUnavailableError extends Error {
    constructor(message) {
      super(message);
      this.constructor = ServerUnavailableError;
      this.__proto__ = ServerUnavailableError.prototype;
      this.message = message;
    }

  }

  _exports.default = ServerUnavailableError;
});