define("baadmin/components/navbar/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex-1 flex">
    <form class="w-full flex md:ml-0" action="#" method="GET">
      <label for="search_field" class="sr-only">Search</label>
      <div class="relative w-full text-gray-400 focus-within:text-gray-600">
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
          <!-- Heroicon name: solid/search -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <input id="search_field" class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search" type="search" name="search">
      </div>
    </form>
  </div>
  {{yield}}
  */
  {
    "id": "NIT5C11A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex-1 flex\"],[12],[2,\"\\n  \"],[10,\"form\"],[14,0,\"w-full flex md:ml-0\"],[14,\"action\",\"#\"],[14,\"method\",\"GET\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"search_field\"],[14,0,\"sr-only\"],[12],[2,\"Search\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"relative w-full text-gray-400 focus-within:text-gray-600\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"absolute inset-y-0 left-0 flex items-center pointer-events-none\"],[12],[2,\"\\n        \"],[3,\" Heroicon name: solid/search \"],[2,\"\\n        \"],[10,\"svg\"],[14,0,\"h-5 w-5\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 20 20\"],[14,\"fill\",\"currentColor\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n          \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"input\"],[14,1,\"search_field\"],[14,0,\"block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm\"],[14,\"placeholder\",\"Search\"],[14,3,\"search\"],[14,4,\"search\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "baadmin/components/navbar/search.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});