define("baadmin/services/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocumentService extends Ember.Service {
    redirectTo(url) {
      if (window) {
        window.location.href = url;
      }
    }

    getLocation() {
      return window.location;
    }

    reload() {
      document.location.reload();
    }

    printPage() {
      if (window) {
        window.print();
      }
    }

  }

  _exports.default = DocumentService;
});