define("baadmin/components/sidebar/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (default @activeClass "is-active") as |activeClass|}}
    <Sidebar::Link
      @href={{@href}}
      @route={{@route}}
      @model={{@model}}
      @external={{@external}}
      @activeClass={{activeClass}}
      @currentWhen={{@currentWhen}}
      class={{cn
        "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        (if (and @active activeClass) activeClass)
      }}
      data-test-menu-item
      ...attributes
      {{on "click" (optional @onClick)}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{#if @icon}}
          {{svg-jar @icon class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" data-test-menu-icon=@icon}}
        {{/if}}
  
        {{@label}}
      {{/if}}
    </Sidebar::Link>
  {{/let}}
  */
  {
    "id": "ctn+YW9m",
    "block": "{\"symbols\":[\"activeClass\",\"@icon\",\"@label\",\"&default\",\"@href\",\"@route\",\"@model\",\"@external\",\"@currentWhen\",\"@active\",\"&attrs\",\"@onClick\",\"@activeClass\"],\"statements\":[[6,[37,7],[[30,[36,6],[[32,13],\"is-active\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"sidebar/link\",[[16,0,[30,[36,3],[\"text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md\",[30,[36,1],[[30,[36,2],[[32,10],[32,1]],null],[32,1]],null]],null]],[24,\"data-test-menu-item\",\"\"],[17,11],[4,[38,5],[\"click\",[30,[36,4],[[32,12]],null]],null]],[[\"@href\",\"@route\",\"@model\",\"@external\",\"@activeClass\",\"@currentWhen\"],[[32,5],[32,6],[32,7],[32,8],[32,1],[32,9]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,2]],[[\"class\",\"data-test-menu-icon\"],[\"text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6\",[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\",\"and\",\"cn\",\"optional\",\"on\",\"default\",\"let\"]}",
    "meta": {
      "moduleName": "baadmin/components/sidebar/item.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});