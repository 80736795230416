define("baadmin/routes/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RegisterRoute extends Ember.Route {}

  _exports.default = RegisterRoute;
});