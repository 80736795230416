define("baadmin/mixins/version-header-handler", ["exports", "ember-new-version-detection/mixins/version-header-handler"], function (_exports, _versionHeaderHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _versionHeaderHandler.default;
    }
  });
});